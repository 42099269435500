
<template>
  <navigation index="applyFor" ref="navigation">
    <div :style="{ height: ContentHeight + 'px' }">
      <div class="topnavigation" style="gap: 8px">
        <el-button>
          <el-checkbox
            v-model="IsDispose"
            label="查看未处理"
            @change="GetStoreRenew"
          ></el-checkbox>
        </el-button>

        <el-input
          v-model="SearchText"
          style="width: 220px"
          placeholder="请输入编号进行搜索"
          @keyup.enter="GetStoreRenew"
        ></el-input>
        <el-button type="primary" @click="GetStoreRenew">
          <el-icon class="no-inherit">
            <Search />
          </el-icon>
          搜索</el-button
        >
      </div>

      <el-table
        border
        :data="StoreRenewDatas"
        style="width: 100%"
        :height="TableHeight + 'px'"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column label="申请编号" width="120" prop="Number">
        </el-table-column>
        <el-table-column label="申请时间" width="180" prop="CreateTimeFormat">
        </el-table-column>
        <el-table-column label="到期时间" width="180" prop="CreateTimeFormat">
        </el-table-column>
        <el-table-column label="支付方式" width="130" prop="PayType">
          <template #default="scope">
            <el-tag v-if="scope.row.PayType == 'wechat'">微信</el-tag>
            <el-tag v-if="scope.row.PayType == 'alipay'">支付宝</el-tag>
            <el-tag v-if="scope.row.PayType == 'transfer'">转账</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="是否处理" width="100" prop="IsDispose">
          <template #default="scope">
            <el-tag v-if="scope.row.IsDispose">已处理</el-tag>
            <el-tag v-if="!scope.row.IsDispose" type="danger">未处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="申请店铺" width="160">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span style="margin-left: 10px">{{ scope.row.Store.Name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="申请活动" width="180" prop="ProjectName">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span style="margin-left: 10px">{{
                scope.row.StorePricing.Name
              }}</span>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="备注" width="180" prop="Remark">
        </el-table-column> -->
        <el-table-column label="操作" fixed="right" width="120">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="OpenStoreRenew(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="margin: 5px">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentChange"
          :page-size="padding.Size"
          :total="padding.Count"
        >
        </el-pagination>
      </div>
    </div>
  </navigation>

  <!-- 审核申请 -->
  <el-dialog
    v-model="StoreRenewWindow"
    title="审核续费申请"
    width="800px"
    :show-close="false"
  >
    <div style="font-size: 15pt">
    
      <div class="striking">
        {{ StoreRenewOpt.Store.Name }}  
      </div>
      选择的是
      <span class="striking"> {{ StoreRenewOpt.StorePricing.Name }}</span
      >套餐 时长是
      <span class="striking"> {{ StoreRenewOpt.StorePricing.Day }}</span> 天 ,
      赠送天数是
      <span class="striking">{{ StoreRenewOpt.StorePricing.GiveDay }}</span
      >, 价格是
      <span class="striking">{{ StoreRenewOpt.StorePricing.Amount }} </span>
      <p>通过申请后店铺的有效期至</p>
      <div class="striking">{{ StoreRenewOpt.ExpireDateFormat }}</div>
    </div>

    <el-form ref="form" label-width="120px" v-if="StoreRenewOpt.Remark !=null && StoreRenewOpt.Remark !=''">
      <div style="width: 800px" v-html="StoreRenewOpt.Remark"></div>

      <el-divider />
    </el-form>
    <template #footer>
      <span class="dialog-footer" style="display: flex;justify-content: space-between;border-top: 1px solid #eee;padding: 5px;">
        <el-button @click="RejectRenew()" type="danger" style="margin: 5px;" v-if="!StoreRenewOpt.IsDispose">驳回申请</el-button>
    
        <span>
          <el-button @click="StoreRenewWindow = false">关闭</el-button>
     
     <el-button type="primary" @click="DisposeStoreRenew()"
       >通过申请</el-button
     >
        </span>
      </span>
    </template>
  </el-dialog>
</template>
  <script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import "../../../assets/css/commons.css";
import { ElMessageBox, ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";

import navigation from "../../../components/Modules/AdminNavigation.vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value != "") {
        var pattern =
          /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;

        if (!pattern.test(value)) {
          callback(new Error("请输入正确的邮箱"));
        }
      } else {
        callback();
      }
    };

    return {
      TableHeight: document.documentElement.clientHeight - 120,
      ContentHeight: document.documentElement.clientHeight - 200,
      StoreRenewWindow: false,
      StoreRenewOpt: {},
      IsDispose: false,
      padding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      StorePadding: {
        where: {},
      },
    };
  },
  name: "App",
  mounted() {
    this.StoreId = this.$route.query.storeId;

    this.GetStoreRenew();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };
    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;
  },
  components: { navigation, Search },
  methods: {
    //退回
    RejectRenew(){

      ElMessageBox.confirm(
        "确定要<span style='color:red;font-size:14pt'>驳回[" +
          this.StoreRenewOpt.Store.Name +
          "]</span>的 <span  style='color:red;font-size:14pt' > [ " +
          this.StoreRenewOpt.StorePricing.Name +
          "] </span>申请吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {

          axios.apiMethod(
            "/admins/StoreMaintain/RejectStoreRenew",
            "get",
            {
              id: this.StoreRenewOpt.Id,
            },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetStoreRenew();
              } else {
                ElMessage({
                  dangerouslyUseHTMLString: true,
                  message: result.Data.Msg,
                });
              }
              this.StoreRenewWindow = false;
            }
          );
   
          
           })
        .catch(() => {});

    },
    //处理申请
    DisposeStoreRenew(item) {
      ElMessageBox.confirm(
        "确定要通过<span style='color:red;font-size:14pt'>[" +
          this.StoreRenewOpt.Store.Name +
          "]</span>的 <span  style='color:red;font-size:14pt' > [ " +
          this.StoreRenewOpt.StorePricing.Name +
          "] </span>申请吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/admins/StoreMaintain/DisposeStoreRenew",
            "get",
            {
              id: this.StoreRenewOpt.Id,
            },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetStoreRenew();
              } else {
                ElMessage({
                  dangerouslyUseHTMLString: true,
                  message: result.Data.Msg,
                });
              }
              this.StoreRenewWindow = false;
            }
          );
        })
        .catch(() => {});
    },
    ///打开处理页面
    OpenStoreRenew(item) {
      this.StoreRenewWindow = true;
      this.StoreRenewOpt = item;
    },
    GetStoreRenew() {
      this.padding.Where = {
        Name: "",
        Account: "",
        IsDispose: this.IsDispose,
      };

      axios.apiMethod(
        "/admins/StoreMaintain/GetStoreRenew",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.StoreRenewDatas = result.Data.Datas;
            this.padding.Page = result.Data.Page;
            this.padding.Size = result.Data.Size;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },
    //刷新列表
    CurrentChange(index) {
      this.padding.Page = index;
      this.GetStoreUserList();
    },
  },
};
</script>
  
  <style scoped>
.striking {
  color: red;
  font-size: 22pt;
}
</style>
  